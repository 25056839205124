import React, {useRef } from 'react';
import PropTypes from 'prop-types';
import {
  LayersControl,
  WMSTileLayer,
  MapContainer,
} from 'react-leaflet';

import { Marker } from 'react-leaflet';
import L  from 'leaflet';
import 'leaflet.awesome-markers';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import MarkerClusterGroup from 'react-leaflet-markercluster/src/react-leaflet-markercluster';
const { BaseLayer } = LayersControl;



const orangeIcon = L.AwesomeMarkers.icon({
  markerColor: 'orange'
});

const blueIcon = L.AwesomeMarkers.icon({
  markerColor: 'blue'
});


const LeafletMap = ({
                      data,
                      lng,
                      lat,
                      zoom,
                      cluster,
                      setBounds,
                      setZoom,
                      setCenter,
                      query,
                      disableClusteringAtZoom,
                      spiderfyOnMaxZoom,
                      onClickMarker,
                      selectedProduct,
                      ...props
                    }) => {
  const map = useRef(null);
  return (
    <MapContainer
      ref={map}
      {...props}
      zoom={zoom}
      center={(lat && lng) ? [lat, lng] : null}
      boundsOptions={{ padding: [25, 25] }}
    >
      <LayersControl position="topright">
        <BaseLayer checked name="Google Map">
          <ReactLeafletGoogleLayer apiKey={ process.env.REACT_APP_GOOGLE_MAP_KEY} type={'roadmap'} />
        </BaseLayer>
      </LayersControl>
      <MarkerClusterGroup
        disableClusteringAtZoom={17}
        spiderfyOnMaxZoom={true}
      >
        {data.map((item, index) => ({ value: item, index }))
          .filter((item) => item.value.googlePlace !== undefined)
          .map((item) => (
            <Marker
              eventHandlers={{
                click: (e) => {
                  onClickMarker(item.index);
                },
              }}
              disableClusteringAtZoom={20}
              key={`marker-${item.index}`}
              icon={item.index === selectedProduct ? orangeIcon : blueIcon}
              position={[item.value.googlePlace.geoLocationCenter.lat, item.value.googlePlace.geoLocationCenter.lng]}
            >
            </Marker>
          ))}
      </MarkerClusterGroup>
      <WMSTileLayer
        format="image/vnd.jpeg-png"
        transparent
        url="https://desertnetworks.huma-num.fr/geoserver/ows"
      />
    </MapContainer>
  );
};

LeafletMap.defaultProps = {
  query: null,
  cluster: false,
  setBounds: true,
  setZoom: null,
  setCenter: null,
  disableClusteringAtZoom: 9,
  onClickMarker: () => {
  },
  spiderfyOnMaxZoom: false,
};

LeafletMap.propTypes = {
  query: PropTypes.string,
  data: PropTypes.array.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoom: PropTypes.number.isRequired,
  disableClusteringAtZoom: PropTypes.number,
  spiderfyOnMaxZoom: PropTypes.bool,
  onClickMarker: PropTypes.func,
  cluster: PropTypes.bool,
  setBounds: PropTypes.bool,
  setZoom: PropTypes.func,
  setCenter: PropTypes.func,
};

export default LeafletMap;
