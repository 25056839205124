import React from 'react';
import ActivityLoader from '../Loader/ActivityLoader';

const LoadingContent = () => {
  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="col-span-2">
        <div className="grid grid-cols-3 gap-4">
          <ActivityLoader />
          <ActivityLoader />
          <ActivityLoader />
          <ActivityLoader />
          <ActivityLoader />
          <ActivityLoader />
          <ActivityLoader />
          <ActivityLoader />
          <ActivityLoader />
        </div>
      </div>
      <div>
      </div>
    </div>
  );
};


export default LoadingContent;
