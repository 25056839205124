export const difficulties = [
  {label: 'Tous', value: ''},
  {label: 'Très facile', value: 'VERY_EASY'},
  {label: 'Facile', value: 'EASY'},
  {label: 'Modéré', value: 'MODERATE'},
  {label: 'Exigeant', value: 'DEMANDING'},
  {label: 'Stimulant', value: 'CHALLENGING'},
  {label: 'Extrême', value: 'EXTREME'},
];

export const numberOfParticipants = [
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
  {label: '11', value: 11},
  {label: '12', value: 12},
]

export const minimumAges = [
  {label: '0', value: ''},
  {label: '1', value: 1},
  {label: '2', value: 2},
  {label: '3', value: 3},
  {label: '4', value: 4},
  {label: '5', value: 5},
  {label: '6', value: 6},
  {label: '7', value: 7},
  {label: '8', value: 8},
  {label: '9', value: 9},
  {label: '10', value: 10},
  {label: '11', value: 11},
  {label: '12', value: 12},
  {label: '13', value: 13},
  {label: '14', value: 14},
  {label: '15', value: 15},
  {label: '16', value: 16},
  {label: '17', value: 17},
  {label: '18', value: 18},
]

export const activities = [
  { value: "", label: "Toutes" },
  { value: "Astronomie", label: "Astronomie" },
  { value: "Balade a Cheval", label: "Balade à Cheval" },
  { value: "Bapteme De L Air", label: "Baptême de l'air" },
  { value: "Bateau Electrique", label: "Bateau électrique" },
  { value: "Bien Etre", label: "Bien Etre" },
  { value: "Buggy", label: "Buggy" },
  { value: "Canoe / Kayak", label: "Canoe / Kayak" },
  { value: "Canyoning", label: "Canyoning" },
  { value: "Cinema", label: "Cinéma" },
  { value: "Culturel", label: "Culturel" },
  { value: "Educatif", label: "Educatif" },
  { value: "En Famille", label: "En Famille" },
  { value: "Entre Amis", label: "Entre Amis" },
  { value: "Escalade", label: "Escalade" },
  { value: "Extreme", label: "Extreme" },
  { value: "Observation Animaux", label: "Observation des animaux" },
  { value: "Parapente", label: "Parapente" },
  { value: "Parcours Aventure", label: "Parcours Aventure" },
  { value: "Pedalo", label: "Pédalo" },
  { value: "Personne A Mobilite Reduite", label: "Personne à Mobilité Réduite" },
  { value: "Photographie", label: "Photographie" },
  { value: "Rafting", label: "Rafting" },
  { value: "Randonnee Aquatique", label: "Randonnée Aquatique" },
  { value: "Randonnee Pedestre", label: "Randonnée Pédestre" },
  { value: "Saut A L Elastique", label: "Saut à l'élastique" },
  { value: "Seniors", label: "Seniors" },
  { value: "Ski", label: "Ski" },
  { value: "Vélo", label: "Vélo" }
];
