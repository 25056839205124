import React, { createRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Product from './Product';
import { matchSorter } from 'match-sorter';
import LeafletMap from './LeafletMap';

const applyLocalFilter = (items, filters, trekker) => {
  let filtered = items.filter((item) => {
    if (item.flags.includes('NOAG')) {
      return false;
    }
    if(trekker === false && item.title.startsWith('TK')){
      return false;
    }
    if (item.excerpt) {
      const res = item.excerpt.match(/(\d+)\s?ans/);
      if (res) {
        if (res[1] > parseInt(filters.age)) {
          return false;
        }
      }
    }
    return true;
  });
  return matchSorter(filtered, filters.title, { keys: ['title'] })
}


const onClickMarker = (index, productRefs, setSelectedProduct) => {
  setSelectedProduct(index);
  const el = productRefs[index].current;
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -110;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const Results = ({ items, localFilters }) => {
  const [trekker, setTrekker] = useState(false);
  const filteredItems = useMemo(() => applyLocalFilter(items, localFilters, trekker), [items, localFilters, trekker]);
  const [productsRefs, setProductsRefs] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  React.useEffect(() => {
    // add or remove refs
    setProductsRefs((productsRefs) => (
      Array(filteredItems.length).fill().map((_, i) => productsRefs[i] || createRef())
    ));
  }, [filteredItems]);

  return (
    <div className="grid grid-cols-4 gap-4">
      <div className="col-span-2">
        <div className="w-full mb-3 flex justify-between">
          <label className="ml-2">
            <input type="checkbox"
                   className="mr-1"
                   defaultChecked={trekker}
                   onChange={() => setTrekker(!trekker)}
            />
            Trekker
          </label>
          <span className="inline-flex text-xl items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-blue-500 rounded">{filteredItems.length} résultats</span>
        </div>
        <div className="grid grid-cols-3 gap-4">
          {filteredItems.map((item, index) => (
            <Product
              key={item.id}
              isSelected={selectedProduct === index}
              onClick={() => setSelectedProduct(index)}
              productRef={productsRefs[index]}
              item={item}
            />
          ))}
        </div>
      </div>
      <div className="sticky col-span-2 top-0 h-11"
           style={{ top: '131px', marginTop: '35px', width: '100%', height: '400px' }}>
        <LeafletMap
          lat={43.7669581}
          lng={6.1691765}
          zoom={10}
          selectedProduct={selectedProduct}
          data={filteredItems}
          onClickMarker={(index) => onClickMarker(index, productsRefs, setSelectedProduct)}
        />
      </div>
    </div>
  )
    ;
};


Results.propTypes = {
  items: PropTypes.array.isRequired,
  localFilters: PropTypes.object.isRequired,
};

export default Results;
