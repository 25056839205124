import './App.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import BokunSearch from './Scenes/BokunSearch';

const queryClient = new QueryClient();


function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="container mx-auto">
        <BokunSearch />
      </div>
    </QueryClientProvider>
  );
}

export default App;
