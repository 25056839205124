import crypto from 'crypto';
import axios from 'axios';

const getBokunHeaders = (requestMode, path) => {
  const today = new Date();
  const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  const dateTime = date + ' ' + time;
  const accessKey = process.env.REACT_APP_BOKUN_ACCESS_KEY;
  const secretKey = process.env.REACT_APP_BOKUN_SECRET_KEY;
  const requestDate = dateTime;

  const headerString = requestDate + accessKey + requestMode + path;

  const requestSignature = crypto.createHmac("sha1", secretKey)
    .update(headerString)
    .digest()
    .toString('base64');

  return {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Bokun-AccessKey': accessKey,
    'X-Bokun-Date': requestDate,
    'X-Bokun-Signature': requestSignature
  };
}


/*const getDetailsPath = (id) => {
  return '/activity.json/' + id + '?currency=EUR&lang=FR';
}*/

export const searchActivities = (parameters) => {
  const bokunApiUrl = 'https://api.bokun.is';
  const lang = 'lang=fr';
  const currency = "currency=EUR";

  const searchPath = '/activity.json/search?' + currency + '&' + lang;

  const requestUri = bokunApiUrl + searchPath;

  return axios({
    method: 'post',
    url: requestUri,
    headers: getBokunHeaders('POST', searchPath),
    data: JSON.stringify(parameters)
  }).then((res) => res.data);
}
