import React, { useState } from "react";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import 'moment/locale/fr';
import moment from 'moment';
import PropTypes from 'prop-types';

function DatePicker({startDate, endDate, onChange, label}) {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <>
      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
        {label}
      </label>
      <div style={{ width: '550px'}} >
        <DateRangePicker
          startDatePlaceholderText="début"
          endDatePlaceholderText="fin"
          startDate={startDate}
          startDateId="tata-start-date"
          endDate={endDate}
          endDateId="tata-end-date"
          onDatesChange={onChange}
          focusedInput={focusedInput}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          displayFormat={() => moment.localeData('fr').longDateFormat('L')}

        />
      </div>
    </>

  );
}

DatePicker.defaultProps = {
  startDate: null,
}

DatePicker.propTypes = {
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
}

export default DatePicker;
