import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { searchActivities } from '../Services/bokun';
import Filters from '../Components/Search/Filters';
import { formatBokunParameters } from '../Services/search';
import Results from '../Components/Search/Results';
import LoadingContent from '../Components/Search/LoadingContent';


const initialFilters = {
  age: '',
  title: '',
  numberOfParticipants: 1,
  activity: '',
  difficulty: '',
  startDate: null,
  endDate: null,
}

const BokunSearch = () => {
  const [filters, setFilters] = useState(initialFilters);
  const bokunParameters = useMemo(() => formatBokunParameters(filters), [filters]);
  const { isLoading, error, data } = useQuery(['bokun_activities', bokunParameters],
    () => searchActivities(bokunParameters), { refetchOnWindowFocus: false })

  if (error) return "An error has occurred: " + error.message;

  return (
    <>
      <div className="sticky pt-8 top-0 bg-white z-50	">
        <Filters filters={filters} setFilters={setFilters} />
        <hr className="mt-3 mb-3" />
      </div>
      <>
        {isLoading ? (
          <LoadingContent />
        ) : (
          <Results items={data.items} localFilters={{ 'age': filters.age, 'title': filters.title }} />
        )}
      </>
    </>
  );
};

export default BokunSearch;
