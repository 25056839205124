import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const cleanExcerpt = (excerpt) => {
  if (excerpt === undefined) return '';
  const split = excerpt.split('|');
  if (split[1] !== undefined) return split[1];
  return '';
};
const getCutoff = (item) => {
  if (item.fields.bookingCutoffDays !== 0) return item.fields.bookingCutoffDays + ' jour(s)';
  if (item.fields.bookingCutoffHours !== 0) return item.fields.bookingCutoffHours + ' heure(s)';
  if (item.fields.bookingCutoffMinutes !== 0) return item.fields.bookingCutoffMinutes + ' minute(s)';
  if (item.fields.bookingCutoffWeeks !== 0) return item.fields.bookingCutoffWeeks + ' semaine(s)';
  return null;
}

const Product = ({ item, productRef, onClick, isSelected }) => {
  const cutOff = useMemo(() => getCutoff(item), [item]);

  return (
    <>
      <div ref={productRef}
           onClick={onClick}
           className={`cursor-pointer rounded overflow-hidden shadow-lg bg-white flex flex-col ${isSelected ? 'transform shadow-xl border-2 border-yellow-300' : ''}`}
      >
        {item.keyPhoto && (
          <div className="keyPhoto">
            <img className="w-full" src={item.keyPhoto.originalUrl} alt="Sunset in the mountains" />
          </div>
        )}
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{item.title}</div>
          <div className="text-l mb-2 text-gray-400">{item.vendor.title}</div>
          <p className="text-gray-700 text-base"
             dangerouslySetInnerHTML={{ __html: cleanExcerpt(item.excerpt) }}>
          </p>
          {cutOff && (
            <div className="mt-3">
              cut-off :
              <span className="ml-1 text-red-400 rounded-sm text-gray-700 text-base px-1 py-1 leading-none">
            {cutOff}
            </span>
            </div>
          )}
        </div>
        <div className="px-3 pt-4 pb-4 w-100 flex mt-auto">
          <div className="flex-1 flex items-center font-bold text-xl text-gray-600">
            <span className="block bg-green-400 rounded-md text-white text-xs font-bold px-3 py-2 leading-none flex items-center">
              {item.price}€
            </span>
          </div>
          <a href={process.env.REACT_APP_BOKUN_BOOKING_URL + item.id}
             target="_blank"
             rel="noreferrer"
             className="text-center flex-1 mt-auto bokun-blue-bg hover:bokun-blue-bg text-white font-bold py-1 px-1 rounded"
          >
            Réserver
          </a>
        </div>
      </div>
    </>
  );
};

Product.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Product;
