import React from 'react';
import PropTypes from 'prop-types';

const InputText = ({ label, value, onChange, placeholder }) => {
  return (
    <>
      <label
        className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
        htmlFor="grid-city"
      >
        {label}
      </label>
      <input
        onChange={(event) => onChange(event.target.value)}
        value={value}
        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        id="grid-city"
        type="text"
        placeholder={placeholder}
      />
    </>
  );
};

InputText.defaultProps = {
  placeholder: '',
  value: ''
}

InputText.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default InputText;
