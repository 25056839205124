import React from 'react';
import DatePicker from '../Input/DatePicker';
import InputSelect from '../Input/InputSelect';
import { activities, difficulties, minimumAges, numberOfParticipants } from '../../Services/verdon';
import PropTypes from 'prop-types';
import InputText from '../Input/InputText';

const updateFilter = (field, value, setFilters) => {
  setFilters((previousState) => {
    return { ...previousState, [field]: value };
  });
}

const handleDatesChange = (startDate, endDate, setFilters) => {
  updateFilter('startDate', startDate, setFilters);
  updateFilter('endDate', endDate, setFilters);
};

const Filters = ({ filters, setFilters }) => {
  return (
    <div>
      <form className="w-full">
        <div className="flex mb-2">
          <div className="w-full md:w-64 px-2 mb-6 md:mb-0">
            <DatePicker
              label="Dates"
              startDate={filters.startDate}
              endDate={filters.endDate}
              onChange={({ startDate, endDate }) => handleDatesChange(startDate,
                endDate,
                setFilters)}
            />
          </div>
          <div className="w-full md:w-1/3 px-2 mb-6 md:mb-0">
            <InputSelect
              value={filters.activity}
              onChange={(value) => updateFilter('activity', value, setFilters)}
              label="Activité"
              options={activities}
            />
          </div>
          <div className="w-full md:w-1/4 px-2 mb-6 md:mb-0">
            <InputSelect
              value={filters.numberOfParticipants}
              onChange={(value) => updateFilter('numberOfParticipants', value, setFilters)}
              options={numberOfParticipants}
              label="Nombre de participants"
            />
          </div>
          <div className="w-full md:w-1/4 px-2 mb-6 md:mb-0">
            <InputSelect
              value={filters.age}
              onChange={(value) => updateFilter('age', value, setFilters)}
              options={minimumAges}
              label="Age minimum"
            />
          </div>
          <div className="w-full md:w-1/4 px-2 mb-6 md:mb-0">
            <InputSelect
              value={filters.difficulty}
              onChange={(value) => updateFilter('difficulty', value, setFilters)}
              label="Niveau"
              options={difficulties}
            />
          </div>
          <div className="w-full md:w-1/4 px-2 mb-6 md:mb-0">
            <InputText
              value={filters.title}
              onChange={(value) => updateFilter('title', value, setFilters)}
              label="Titre"
              placeholder="Titre"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

Filters.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default Filters;
