export const formatBokunParameters = (searchFilters) => {

  const facetFilters = [];

  const {
    activity,
    difficulty,
    startDate,
    endDate,
    numberOfParticipants
  } = searchFilters;

  const filters = {
    'operator': 'AND',
    'searchExternalId': false,
    'searchFullText': false,
    'searchKeywords': true,
    'searchTitle': false,
    'text': activity ? activity : '',
  };


  if(difficulty !== ''){
    facetFilters.push({
      excluded: false,
      name: 'difficulty',
      values: [difficulty ? difficulty: 'EASY'],
    });
  }

  return {
    'excludeBoxes': false,
    'sortField': 'ALPHABETIC',
    'participants': numberOfParticipants,
    'pageSize': 200,
    'startDate': startDate ? startDate.format('YYYY-MM-DD') : '',
    'endDate': endDate ? endDate.format('YYYY-MM-DD') : '',
    'textFilter': filters,
    facetFilters
  };
}
